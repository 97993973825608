<template>
  <b-modal
    v-model="onControl"
    ref="roundDetailDashboard"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="`DETAILS OF ${title.toUpperCase()}`"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-wrap
  >
    <div class="container p-1" style="max-height: 800px; overflow-y: auto" v-if="filteredData.length > 0">
      <b-card v-for="(data, index) in filteredData" :key="index" no-body>
        <b-card-title class="text-center text-primary fw-bold mb-0 mt-2">
          {{ getCardTitle(data[0].key) }}
        </b-card-title>
        <b-row
          v-for="(item, idx) in data"
          :key="idx"
          class="py-1 card-round mt-1 mx-1"
          :class="isDarkSkin ? 'dark' : 'light'"
        >
          <b-col
            v-for="(field, i) in getCardFields(item.key)"
            cols="12"
            md="6"
            :xl="field.cols"
            :key="i"
          >
            <h5 class="text-left fw-bold">{{ field.label }}</h5>
            <p
              v-if="field.value === 'created_at'"
              class="rounded border-secondary text-center text-truncate text-padding"
              v-b-tooltip:title="item[field.value]"
            >
              {{ item[field.value] | myGlobalDay }}
            </p>
            <p
              v-else
              class="rounded border-secondary text-center text-truncate text-padding"
              v-b-tooltip:title="item[field.value]"
            >
              {{ item[field.value] }}
            </p>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else class="text-center text-secondary my-2" style="font-size: 1.5rem; font-weight: bolder">
      <span>No information found for this sent round. Items were already disputed in another process</span> 
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      onControl: false,
    };
  },
  created() {
    this.onControl = true;
  },
  methods: {
    getCardTitle(key) {
      const titles = {
        ac: "ACCOUNTS",
        in: "INQUIRIES",
        pi: "PERSONAL INFORMATION",
        pr: "PUBLIC RECORDS",
      };

      return titles[key];
    },
    getCardLabels(key) {
      const labels = {
        ac: [
          "Account",
          "Account #",
          "Account Type",
          "Account Status",
          "Reason",
          "Instruction",
        ],
        in: ["Inquiry dispute", "Reason", "Instruction"],
        pi: ["Personal dispute", "Reason", "Instruction"],
        pr: ["Public dispute", "Reason", "Instruction"],
      };

      return labels[key];
    },

    getCardFields(key) {
      const values = {
        ac: [
          { label: "Creditor", value: "name", cols: "3" },
          { label: "Account", value: "a_desc", cols: "3" },
          { label: "Account #", value: "account_number", cols: "3" },
          { label: "Account Type", value: "account_type_text", cols: "3" },
          { label: "Account Status", value: "a_status", cols: "3" },
          { label: "Bureau", value: "bureau_name", cols: "3" },
          { label: "Status", value: "status_value", cols: "3" },
          { label: "Date", value: "created_at", cols: "3" },
          { label: "Reason", value: "reason_text", cols: "6" },
          { label: "Instruction", value: "instruction_text", cols: "6" },
        ],
        in: [
          { label: "Inquiry Dispute", value: "content", cols: "4" },
          { label: "Bureau", value: "bureau_name", cols: "4" },
          { label: "Status", value: "status_value", cols: "4" },
          { label: "Date", value: "created_at", cols: "4" },
          { label: "Reason", value: "reason_text", cols: "4" },
          { label: "Instruction", value: "instruction_text", cols: "4" },
        ],
        pi: [
          { label: "Personal Dispute", value: "content", cols: "4" },
          { label: "Bureau", value: "bureau_name", cols: "4" },
          { label: "Status", value: "status_value", cols: "4" },
          { label: "Date", value: "created_at", cols: "4" },
          { label: "Reason", value: "reason_text", cols: "4" },
          { label: "Instruction", value: "instruction_text", cols: "4" },
        ],
        pr: [
          { label: "Public Record", value: "public_record", cols: "4" },
          { label: "Bureau", value: "bureau_name", cols: "4" },
          { label: "Status", value: "status_value", cols: "4" },
          { label: "Date", value: "created_at", cols: "4" },
          { label: "Reason", value: "reason_text", cols: "4" },
          { label: "Instruction", value: "instruction_text", cols: "4" },
        ],
      };

      if (!this.isSpecialist) {
        values.ac = values.ac.filter(
          (item) =>
            item.value !== "reason_text" && item.value !== "instruction_text"
        );
        values.in = values.in.filter(
          (item) =>
            item.value !== "reason_text" && item.value !== "instruction_text"
        );
        values.pi = values.pi.filter(
          (item) =>
            item.value !== "reason_text" && item.value !== "instruction_text"
        );
        values.pr = values.pr.filter(
          (item) =>
            item.value !== "reason_text" && item.value !== "instruction_text"
        );
      }

      return values[key];
    },
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    cardVariant() {
      return this.isDarkSkin ? "dark" : "light";
    },
    filteredData() {
      return Object.values(this.data).filter((value) => value.length > 0);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isSpecialist() {
      return [28, 29, 30].includes(this.moduleId);
    },
  },
};
</script>

<style lang="scss" scoped>
.fw-bold {
  font-weight: bold;
}

.text-padding {
  padding: 2px 5px !important;
}

.card-round {
  border-radius: 10px;
  padding: 1rem 0;
  margin: 20px 0;
  background: linear-gradient(90deg, #e1e0e0 0%, #dddddd 100%);
  &.dark {
    background: linear-gradient(90deg, #262626 0%, #333334 100%);
  }
}
</style>
