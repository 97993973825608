var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('b-table',{attrs:{"id":"my-table","current-page":_vm.paginateCredit.currentPage,"per-page":_vm.paginateCredit.perPage,"striped":"","small":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(bureau_id)",fn:function(ref){
var item = ref.item;
return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
          item.bureau_id == 1
            ? 'Transunion'
            : item.bureau_id == 2
            ? 'Experian'
            : 'Equifax'
        ),expression:"\n          item.bureau_id == 1\n            ? 'Transunion'\n            : item.bureau_id == 2\n            ? 'Experian'\n            : 'Equifax'\n        ",modifiers:{"hover":true,"top":true}}],staticClass:"mr-1 ml-1",attrs:{"src":item.bureau_id == 1
            ? '/assets/images/icons/transunion.png'
            : item.bureau_id == 2
            ? '/assets/images/icons/experian.png'
            : '/assets/images/icons/equifax.png'}})]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.getColorStatus(data.value)}},[_vm._v(" "+_vm._s(_vm.statusName(data.value))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }