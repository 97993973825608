<template>
  <div>
    <div class="d-flex justify-content-between">
      <div style="width: 40%" class="d-flex justify-content-between">
        <span class="k-font-weight-bold font-medium-1">NOTES</span>
        <b-form-select
          v-model="year"
          :options="yearOptions"
          class="ml-1"
          @input="getNotesPerYear"
        />
      </div>
      <b-button
        :disabled="isResponsibleAccount"
        variant="primary"
        @click="openAddNotesModal"
      >
        <feather-icon icon="PlusIcon" />ADD NOTE
      </b-button>
    </div>
    <div class="ml-n1 mr-n1">
      <div class="d-flex flex-wrap">
        <div
          v-for="(month, index) in months"
          :key="index"
          class="m-1 card rounded card-width"
        >
          <div
            class="text-center text-white rounded-top bg-secondary"
            style="padding-top: 0.4rem; padding-bottom: 0.4rem"
          >
            <span class="font-small-3">{{ months[index] }}</span>
          </div>
          <div
            class="text-center rounded-bottom cursor-pointer"
            :class="isDarkSkin ? 'border-dark' : 'border-light'"
            style="padding-top: 0.4rem; padding-bottom: 0.4rem"
            @click="openModalAllNotes(index + 1)"
          >
            <span class="text-primary">{{ getNumberOfNotes(index) }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <b-button
        :disabled="isResponsibleAccount"
        variant="warning"
        @click="openFirstNoteModal"
      >
        <tabler-icon icon="FileTextIcon" class="mr-half" />FIRST NOTE
      </b-button>
      <b-button
        v-if="idNotes != null"
        @click="showSummaryModal = true"
        variant="info"
        class="ml-1"
      >
        <feather-icon icon="PhoneIcon" />
        SALE CALL
      </b-button>
      <b-button
        v-if="
          date_start >= '2020-12-05' &&
          (moduleId === 6 || client.program_id == 3)
        "
        :variant="status_wel == 1 ? 'warning' : 'secondary'"
        class="ml-custom"
        :disabled="isResponsibleAccount"
        @click="openNotesWelcomeCe()"
      >
        <tabler-icon icon="FileInvoiceIcon" class="mr-half" />WELCOME</b-button
      >
      <b-button
        v-if="
          date_start >= '2021-01-05' &&
          (moduleId === 7 || client.program_id == 2)
        "
        :variant="status_wel == 1 ? 'warning' : 'secondary'"
        class="ml-custom"
        :disabled="isResponsibleAccount"
        @click="openNotesWelcomeBc()"
      >
        <tabler-icon icon="FileInvoiceIcon" class="mr-half" />WELCOME</b-button
      >
      <b-button
        v-if="date_start >= '2020-12-05' && moduleId === 6 && status_wel == 0"
        variant="secondary"
        class="ml-custom"
        :disabled="isResponsibleAccount"
        @click="openNotesWelcomeBc()"
      >
        <tabler-icon icon="FileInvoiceIcon" class="mr-half" />WELCOME</b-button
      >
      <b-button
        v-if="
          date_start >= '2021-01-08' &&
          (moduleId === 5 || client.program_id == 4)
        "
        :variant="status_wel == 1 ? 'warning' : 'secondary'"
        class="ml-custom"
        :disabled="isResponsibleAccount"
        @click="openNotesWelcomeDS()"
      >
        <tabler-icon icon="FileInvoiceIcon" class="mr-half" />WELCOME</b-button
      >
      <b-button
        v-if="date_start >= '2021-01-08' && moduleId === 20"
        :variant="status_wel == 1 ? 'warning' : 'secondary'"
        class="ml-custom"
        :disabled="isResponsibleAccount"
        @click="openNotesWelcomeCe()"
      >
        <tabler-icon icon="FileInvoiceIcon" class="mr-half" />
        WELCOME
      </b-button>
      <!--created services-->
      <!-- <b-button
        v-if="
          moduleId === 28 ||
          moduleId === 29 ||
          moduleId === 5 ||
          moduleId === 6 ||
          moduleId === 7 ||
          moduleId === 20 ||
          moduleId === 22 ||
          moduleId === 25 ||
          onlyRead
        "
        variant="warning"
        class="ml-custom"
        @click="openModalApps"
      >
        <feather-icon icon="EyeIcon" class="mr-half" />APPLICATIONS</b-button
      > -->

      <b-dropdown
        v-if="statewp > 0 || statewp2 > 0"
        variant="outline-warning"
        class="ml-custom"
        dropup
      >
        <template #button-content>
          <tabler-icon icon="FileTextIcon" class="mr-half" />
          WORK PLAN ({{ statewp2 + statewp }})
        </template>
        <b-dropdown-item
          v-for="(item, index) in arrworks"
          :key="index"
          @click="
            item.type == 'old'
              ? openWorkPlanModal(item.id)
              : openWorkPlanModalNew(item)
          "
        >
          <span>{{ item.created_at | myGlobalDay }}</span>
          <feather-icon
            v-if="item.type == 'new'"
            icon="RadioIcon"
            class="text-primary"
            style="margin-left: 5px"
          />
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-if="
          (moduleId === 7 ||
            moduleId === 6 ||
            moduleId === 4 ||
            moduleId === 5 ||
            moduleId === 11 ||
            moduleId === 20 ||
            moduleId === 22 ||
            moduleId === 25 ||
            onlyRead) &&
          statewp == 0 &&
          currentUser.role_id == 2
        "
        variant="secondary"
        class="ml-custom"
        :disabled="isResponsibleAccount"
      >
        <tabler-icon icon="FileTextIcon" class="mr-half" />WORK PLAN</b-button
      >
      <b-button
        v-if="
          (moduleId === 7 ||
            moduleId === 6 ||
            moduleId === 4 ||
            moduleId === 5 ||
            moduleId === 11 ||
            moduleId === 20 ||
            moduleId === 22 ||
            moduleId === 25 ||
            onlyRead) &&
          statewp == 0 &&
          currentUser.role_id == 1
        "
        variant="secondary"
        class="ml-custom"
        :disabled="isResponsibleAccount"
      >
        <tabler-icon icon="FileTextIcon" class="mr-half" />WORK PLAN</b-button
      >
      <UpdateComponent />
    </div>

    <div class="d-flex justify-content-between">
      <span class="k-font-weight-bold font-medium-1">TASK</span>
    </div>

    <b-table
      no-border-collapse
      class="mt-1 position-relative"
      :fields="fields"
      show-empty
      style="max-height: 30vh"
      sticky-header="30vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(date)="data">
        <span>{{ data.item.date | myGlobalDay }}</span>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-if="
            (data.item.user_id == currentUser.user_id ||
              isTeamLeader ||
              isCeo ||
              isSupervisor) &&
            !onlyRead
          "
          class="mr-half text-success"
          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
          icon="CheckCircleIcon"
          size="15"
          @click="isResponsibleAccount ? '' : checkTask(data.item.id)"
        />
        <feather-icon
          v-if="
            (data.item.creator_id == currentUser.user_id ||
              isTeamLeader ||
              isCeo ||
              isSupervisor) &&
            !onlyRead
          "
          class="mr-half text-warning"
          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
          size="15"
          icon="EditIcon"
          @click="isResponsibleAccount ? '' : editTask(data.item)"
        />
        <feather-icon
          class="mr-half text-primary"
          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
          size="15"
          icon="EyeIcon"
          @click="isResponsibleAccount ? '' : showTask(data.item)"
        />
        <feather-icon
          v-if="
            (data.item.creator_id == currentUser.user_id ||
              isTeamLeader ||
              isCeo ||
              isSupervisor) &&
            !onlyRead
          "
          class="mr-half text-danger"
          :class="isResponsibleAccount ? '' : 'cursor-pointer'"
          icon="TrashIcon"
          size="15"
          @click="isResponsibleAccount ? '' : deleteTask(data.item.id)"
        />
      </template>
    </b-table>
    <div class="mt-1 d-flex justify-content-between">
      <b-button variant="primary" class="mr-1" @click="openListCompletedTasks">
        <feather-icon icon="EyeIcon" class="mr-1" />VIEW COMPLETED TASKS
      </b-button>
      <b-button
        :disabled="isResponsibleAccount"
        v-if="!onlyRead"
        variant="primary"
        @click="openAddTaskCreate"
      >
        <feather-icon icon="PlusIcon" /> ADD TASK
      </b-button>
    </div>

    <add-task-modal
      v-if="openAddTaskController"
      :task-data="taskToSend"
      :to-edit="toEdit"
      :show-mood="showMood"
      :aid="client.advisor_id"
      :aname="client.advisor_name"
      :sid="client.supervisor_id"
      :sname="client.supervisor_name"
      :name-client="client.client_name"
      @close="closeCreateModalTask"
      @reload="getAllTasks"
    />
    <list-completed-tasks-modal
      v-if="listCompletedTasksController"
      :name-client="client.client_name"
      @close="closeListCompletedTasks"
    />
    <add-notes-modal
      v-if="addNotesModalController"
      :name-client="client.client_name"
      :editing="false"
      @close="closeAddNotesModal"
      @reload="getNotesPerYear(year)"
    />
    <list-all-notes-modal
      v-if="openModalAllNotesController"
      :month="monthToSend"
      :year="year"
      :name-client="client.client_name"
      @close="closeModalAllNotes"
    />
    <modal-view-applications
      v-if="openModalAppsController"
      :typeService="typeServiceSelected"
      @close="closeModalApps"
    />
    <modal-add-work-plan
      v-if="workPlanController"
      :id-work-plan="idWorkPlan"
      :client-name="client.client_name"
      :account="client.account"
      @reload="getWhenWorkPlanIsCreated"
      @close="closeWorkPlanModal"
    />
    <notes-welcome-ds-modal
      v-if="notesWelcomeDsModalController"
      :client="client"
      @close="closeNotesWelcomeDS"
      @reload="getStatusWelOnChange"
    />
    <notes-welcome-bc-modal
      v-if="notesWelcomeBcModalController"
      :client="client"
      @close="closeNotesWelcomeBc"
      @reload="getStatusWelOnChange"
    />
    <notes-welcome-ce-modal
      v-if="notesWelcomeCeModalController"
      :client="client"
      @close="closeNotesWelcomeCe"
      @reload="getStatusWelOnChange"
    />
    <modal-notes-all
      v-if="firstNoteController"
      :note-info="noteInfo"
      :is-from-task-notes="true"
      @hide="closeFirstNoteModal"
    />

    <modal-notes-credit-new
      v-if="openModalNotesNewCe"
      :note-info="noteInfo"
      @hide="closeNotesNewCredit"
    />

    <ModalNotesTax
      v-if="openModalNotesTaxController"
      :note-info="noteInfo"
      @hide="closeModalNotesTax"
    />
    <modal-notes-boost
      v-if="boostCreditController"
      :note-info="noteInfo"
      @hide="closeBoostCreditModal"
    />
    <modal-notas-credit
      v-if="creditExpertsDigitalController"
      :note-info="noteInfo"
      :from-dashboard="true"
      @hide="closeCreditExpertsDigitalModal"
    />
    <ModalNotesFirst
      v-if="isActiveModalBusinessNotes"
      :note-info="noteInfo"
      @hide="closeModalBusinessNotesAll"
    />
    <modal-notes-paragon
      v-if="isActiveModalNotesAll"
      :note-info="noteInfo"
      @hide="closeModalNotesAll"
    />
    <modal-notes-specialist
      v-if="openModalNotesSpecialist"
      :note-info="noteInfo"
      @hide="closeModalNotesSpecialist"
    />
    <ActionDoneReturnModal
      v-if="showDoneReturnModal"
      :dataClient="dataWp"
      :revision="false"
      @close="showDoneReturnModal = false"
    />
    <CallSummaryModal
      v-if="showSummaryModal"
      :idNotes="idNotes"
      :onlyView="true"
      @closeModal="showSummaryModal = false"
    />
  </div>
</template>

<script>
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddTaskModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddTaskModal.vue";
import ListCompletedTasksModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListCompletedTasksModal.vue";
import AddNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddNotesModal.vue";
import ModalViewApplications from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalViewApplications.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import NotesWelcomeDsModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/NotesWelcomeDsModal.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesCreditNew from "@/views/commons/components/first-notes/credit-experts/ModalNotesCreditNew.vue";
import NotesWelcomeBcModal from "@/views/boost-credit/views/clients/dashboard/options/task-notes-module/NotesWelcomeBcModal.vue";
import NotesWelcomeCeModal from "@/views/credit-experts/views/clients/dashboard/options/task-notes-module/NotesWelcomeCeModal.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import UpdateComponent from "@/views/commons/components/dispute-results/views/components/dashboard/UpdateComponent.vue";
import RequestWorkplanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import CallSummaryModal from "@/views/crm/views/sales-made/components/modals/CallSummaryModal.vue";

export default {
  components: {
    AddTaskModal,
    ListCompletedTasksModal,
    AddNotesModal,
    ListAllNotesModal,
    ModalViewApplications,
    ModalAddWorkPlan,
    NotesWelcomeDsModal,
    NotesWelcomeBcModal,
    NotesWelcomeCeModal,
    ModalNotesAll,
    ModalNotesBoost,
    ModalNotesTax,
    ModalNotasCredit,
    ModalNotesCreditNew,
    ModalNotesFirst,
    ModalNotesParagon,
    ModalNotesSpecialist,
    ActionDoneReturnModal,
    UpdateComponent,
    CallSummaryModal,
  },
  props: {
    idaccount: null,
    allTasks: null,
    allNotes: null,
    roleid: null,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    onlyRead() {
      return this.moduleId === 18;
    },
    notSpecialist() {
      return this.moduleId !== 25 && this.moduleId !== 11;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  watch: {
    client(newValue, oldValue) {
      if (newValue) {
        this.setData();
      }
    },
  },
  async created() {
    this.isResponsible();
    await this.getStatusWelOnChange();
    this.date_start = this.client?.start_date;
    this.status_wel = this.client?.s_welcome;
    this.statewp = this.client?.total_wp;
    this.statewp2 = this.client?.total_wp2;

    this.year = moment().format("YYYY");
    await Promise.all([
      this.getAllTasks(),
      this.getAllWorks(),
      this.getNotesPerYear(this.year),
      this.getNoteIds(),
    ]);
    this.yearSelect();
  },
  data() {
    return {
      idNotes: null,
      showSummaryModal: false,
      isActiveModalNotesAll: false,
      isActiveModalBusinessNotes: false,
      fields: [
        {
          key: "subject",
          label: "Subject",
        },
        {
          key: "date",
          label: "Date/Hour",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      creditExpertController: false,
      noteInfo: {
        nameProgram: "",
        created: "",
        nameClient: "",
        type: 1,
        editModal: false,
        statusSale: 4,
        notSeller: false,
        saleId: "",
        sourcesName: "",
        pack: "",
      },
      notesWelcomeBcModalController: false,
      firstNoteController: false,
      openModalNotesTaxController: false,
      boostCreditController: false,
      arrworks: [],
      status_wel: "",
      date_start: "",
      addNotesModalController: false,
      workPlanController: false,
      statewp: "",
      statewp2: "",
      openModalAppsController: false,
      typeServiceSelected: null,
      notes: [],
      items: [],
      isBusy: false,
      taskToSend: {},
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      toEdit: false,
      openAddTaskController: false,
      openShowTaskController: false,
      showMood: false,
      year: "",
      listCompletedTasksController: false,
      yearOptions: [],
      monthToSend: "",
      openModalAllNotesController: false,
      idWorkPlan: false,
      notesWelcomeDsModalController: false,
      openModalNotesNewCe: false,
      notesWelcomeCeModalController: false,
      creditExpertsDigitalController: false,
      openModalNotesSpecialist: false,
      showDoneReturnModal: false,
      dataWp: null,
    };
  },
  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },
    ...mapActions({
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
    }),
    openModalNotesAll() {
      if (this.moduleId == 3 && this.noteInfo.module == 3) {
        this.isActiveModalBusinessNotes = true;
        return;
      }
      this.isActiveModalNotesAll = true;
    },
    closeModalBusinessNotesAll() {
      this.isActiveModalBusinessNotes = false;
    },
    closeModalNotesAll() {
      this.isActiveModalNotesAll = false;
    },
    async getWhenWorkPlanIsCreated() {
      await this.getAllWorks();
      this.statewp = this.arrworks === null ? 0 : this.arrworks.length;
    },
    async getStatusWelOnChange() {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
      this.status_wel = this.client.s_welcome;
    },
    closeListCompletedTasks() {
      this.listCompletedTasksController = false;
    },
    closeBoostCreditModal() {
      this.boostCreditController = false;
    },
    closeModalNotesTax() {
      this.openModalNotesTaxController = false;
    },
    closeCreditExpertsModal() {
      this.creditExpertController = false;
    },
    yearSelect() {
      for (let x = 2014; x <= moment().format("YYYY"); x++) {
        this.yearOptions.push({ value: x, text: x });
      }
    },
    closeFirstNoteModal() {
      this.firstNoteController = false;
    },
    closeNotesNewCredit() {
      this.openModalNotesNewCe = false;
    },
    openFirstNoteModal() {
      this.setData();
      if (
        (this.moduleId === 7 || this.client.program_id === 2) &&
        this.client.start_date >= "2020-10-30"
      ) {
        this.boostCreditController = true;
      } else if (this.moduleId === 8 || this.client.program_id === 5) {
        this.openModalNotesTaxController = true;
      } else if (
        (this.moduleId === 22 || this.client.program_id === 3) &&
        this.client.start_date >= "2020-10-30"
      ) {
        this.creditExpertsDigitalController = true;
      } else if (this.moduleId === 6) {
        this.openModalNotesNewCe = true;
      } else if (this.client.program_id === 1) {
        this.isActiveModalBusinessNotes = true;
      } else if (this.client.program_id === 7) {
        this.openModalNotesSpecialist = true;
      } else {
        this.firstNoteController = true;
      }
    },
    closeCreditExpertsDigitalModal() {
      this.creditExpertsDigitalController = false;
    },
    closeModalApps() {
      this.openModalAppsController = false;
    },
    openModalApps(typeService) {
      this.typeServiceSelected = typeService;
      this.openModalAppsController = true;
    },
    openListCompletedTasks() {
      this.listCompletedTasksController = true;
    },
    closeAddNotesModal() {
      this.addNotesModalController = false;
    },
    openAddNotesModal() {
      this.addNotesModalController = true;
    },
    closeModalNotesSpecialist() {
      this.openModalNotesSpecialist = false;
    },
    async getAllWorks() {
      try {
        const methodService = "getAllWorksNew";
        const data = await ClientsOptionsServices[methodService]({
          id: this.$route.params.idClient,
        });
        if (data.status === 200) {
          this.arrworks = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getNoteIds() {
      const params = {
        ca_id: this.$route.params.idClient,
      };
      try {
        const { data } = await ClientsOptionsServices.getNoteIds(params);
        this.idNotes = data.id_notes;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openModalAllNotes(monthNumber) {
      this.monthToSend = monthNumber;
      this.openModalAllNotesController = true;
    },
    closeModalAllNotes() {
      this.openModalAllNotesController = false;
    },
    closeWorkPlanModal() {
      this.idWorkPlan = "";
      this.workPlanController = false;
    },
    openWorkPlanModal(id) {
      this.idWorkPlan = id;
      this.workPlanController = true;
    },
    async openWorkPlanModalNew(item) {
      try {
        this.addPreloader();
        const { data } = await RequestWorkplanService.getCompletedWpInfo({
          id: item.id,
        });

        this.dataWp = {
          module_name: this.client.module_name,
          client_name: this.client.client_name,
          client_account: this.client.account,
          agent: data.agent,
          rounds: data.rounds,
          comment: data.comment,
          recommended_services: data.recommended_services,
          workplan_id: data.id,
          creditUtilized: data.creditUtilized,
          deletedAccounts: data.deletedAccounts,
          newAccounts: data.newAccounts,
        };
        this.showDoneReturnModal = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    getNumberOfNotes(index) {
      switch (index) {
        case 0:
          return this.notes.jan;
        case 1:
          return this.notes.feb;
        case 2:
          return this.notes.mar;
        case 3:
          return this.notes.apr;
        case 4:
          return this.notes.may;
        case 5:
          return this.notes.jun;
        case 6:
          return this.notes.jul;
        case 7:
          return this.notes.agu;
        case 8:
          return this.notes.set;
        case 9:
          return this.notes.oct;
        case 10:
          return this.notes.nov;
        case 11:
          return this.notes.dec;
      }
    },
    showTask(item) {
      this.openAddTaskController = true;
      this.showMood = true;
      this.taskToSend = item;
    },
    openAddTaskCreate() {
      this.openAddTaskController = true;
      this.toEdit = false;
      this.taskToSend = {};
    },
    async getAllTasks() {
      this.isBusy = true;
      const data = await ClientsOptionsServices.getAllTasksGrid({
        id: this.$route.params.idClient,
        user_id: this.currentUser.user_id,
      });
      if (data.status === 200) {
        this.items = data.data.data;
        this.isBusy = false;
      }
    },
    async getNotesPerYear(year) {
      const data = await ClientsOptionsServices.getNotesYear({
        id: this.$route.params.idClient,
        year,
      });
      if (data.status === 200) {
        this.notes = data.data;
      }
    },
    async checkTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.checkTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getAllTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    editTask(item) {
      this.taskToSend = item;
      this.toEdit = true;
      this.openAddTaskController = true;
    },
    closeCreateModalTask() {
      this.openAddTaskController = false;
      this.taskToSend = {};
      this.showMood = false;
    },
    async deleteTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getAllTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    openNotesWelcomeBc() {
      this.notesWelcomeBcModalController = true;
    },
    closeNotesWelcomeBc() {
      this.notesWelcomeBcModalController = false;
    },
    openNotesWelcomeDS() {
      this.notesWelcomeDsModalController = true;
    },
    closeNotesWelcomeDS() {
      this.notesWelcomeDsModalController = false;
    },
    setData() {
      this.date_start = this.client.start_date;
      this.status_wel = this.client.s_welcome;
      this.statewp = this.client.total_wp;
      this.statewp2 = this.client.total_wp2;
      this.noteInfo.nameProgram = this.client.program_name;
      this.noteInfo.created = this.client.creates;
      this.noteInfo.nameClient = this.client.client_name;
      this.noteInfo.saleId = this.client.sale_id;
      this.noteInfo.sourcesName = this.client.sourcesname;
      this.noteInfo.pack = this.client.pack;
      this.noteInfo.capturedName = this.client.captured_name;
      this.noteInfo.sellerName = this.client.seller_name;
      this.noteInfo.module = this.client.module;
      this.noteInfo.event_id = this.client.event_id;
    },

    openNotesWelcomeCe() {
      this.notesWelcomeCeModalController = true;
    },
    closeNotesWelcomeCe() {
      this.notesWelcomeCeModalController = false;
    },

    // openDsCourtInfoTasks() {
    //   this.showDsCourtInfoTasks = true;
    // },
  },
};
</script>

<style scoped>
.card-width {
  min-width: 7.6rem;
}
.mr-half {
  margin: 0 !important;
  margin-right: 0.5rem !important;
}
.ml-custom {
  margin-left: 0.65rem !important;
}
@media only screen and (min-width: 1900px) {
  .card-width {
    min-width: 12rem;
  }
}
</style>
