<template>
  <b-dropdown
    v-if="counterUpdate > 0"
    variant="outline-primary"
    class="ml-custom"
    dropup
  >
    <template #button-content>
      <tabler-icon icon="FileTextIcon" class="mr-half" />
      UPDATE ({{ counterUpdate }})
    </template>
    <b-dropdown-item
      v-for="(item, index) in arrworks"
      :key="index"
      @click="openUpdate(item)"
    >
      <span>{{ item.created_recommendation | myGlobalDay }}</span>
      <feather-icon
        icon="RadioIcon"
        class="text-primary"
        style="margin-left: 5px"
      />
    </b-dropdown-item>
    <ActionDoneReturnModal
      v-if="showModalAction"
      :dataClient="dataUpdate"
      :revision="false"
      :section="'UPDATE'"
      @close="showModalAction = false"
    />
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
export default {
  components: {
    ActionDoneReturnModal,
  },
  data() {
    return {
      showModalAction: false,
      dataUpdate: {},
      counterUpdate: 0,
      arrworks: [],
    };
  },
  created() {
    this.getUpdates();
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
    }),
  },
  methods: {
    async getUpdates() {
      try {
        const data = await UpdateService.getUpdates({
          account_id: this.$route.params.idClient,
        });
        this.arrworks = data;
        this.counterUpdate = data.length;
      } catch (error) {
        console.error(error);
      }
    },
    async openUpdate(item) {
      try {
        this.addPreloader();
        const { data } = await UpdateService.getCompletedUrInfo({
          id: item.id,
          score_id: item.score_id,
        });

        this.showModalAction = true;
        this.dataUpdate = item;
        this.dataUpdate.agent = data.agent;
        this.dataUpdate.client_name = data.client_name;
        this.dataUpdate.client_account = data.client_account;
        this.dataUpdate.rounds = data.rounds;
        this.dataUpdate.creditUtilized = data.creditUtilized;
        this.dataUpdate.deletedAccounts = data.deletedAccounts;
        this.dataUpdate.recommended_services = data.recommended_services;
        this.revision = false;
      } catch (error) {
      } finally {
        this.removePreloader();
      }
      this.dataUpdate = item;
      this.showModalAction = true;
    },
  },
};
</script>

<style>
</style>