<template>
  <b-modal
    v-model="onControl"
    title="WELCOME"
    title-tag="h3"
    modal-class="custom-modal-amg"
    header-class="p-0"
    header-bg-variant="transparent"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    scrollable
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>WELCOME</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-card
        no-body
        :header-bg-variant="disabled || checkPresentation ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled || checkPresentation ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <template #header>
          <span>1) PRESENTATION</span>
          <validation-provider
            v-slot="{ errors }"
            name="presentation"
            rules="truthy"
          >
            <b-form-checkbox
              v-model="checkPresentation"
              :disabled="disabled"
              :state="errors[0] && validator ? false : null"
            />
          </validation-provider>
        </template>
      </b-card>
      <b-card
        no-body
        :header-bg-variant="disabled || checkInformation ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled || checkInformation ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <template #header>
          <span>2) INFORMATION</span>
          <validation-provider
            v-slot="{ errors }"
            name="program"
            rules="truthy"
          >
            <b-form-checkbox
              v-model="checkInformation"
              :disabled="disabled"
              @change="editInformation"
              :state="errors[0] && validator ? false : null"
            />
          </validation-provider>
        </template>
        <b-card-body class="mt-2">
          <quill-editor
            v-model="information"
            style="width: 100%"
            class="quill-br"
            :disabled="disabled"
            :options="editorOption"
          />
        </b-card-body>
      </b-card>
      <b-card
        no-body
        :header-bg-variant="disabled || checkGoals ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled || checkGoals ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <template #header>
          <span> 3) GOALS</span>
          <validation-provider v-slot="{ errors }" name="goals" rules="truthy">
            <b-form-checkbox
              v-model="checkGoals"
              :disabled="disabled"
              :state="errors[0] && validator ? false : null"
              @change="editGoals()"
            />
          </validation-provider>
        </template>
        <b-card-body class="mt-2">
          <v-select
            :dir="'ltr'"
            :multiple="true"
            v-model="typeGoal"
            transition="multiple"
            :class="[{ 'disable-class': goalsedit }]"
            label="name"
            :options="goalOptions"
            :reduce="(el) => el.id"
          />
        </b-card-body>
      </b-card>
      <b-card
        header="4) RECOMMENDATIONS"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <b-card-body>
          <div class="d-flex justify-content-between align-items-start">
            <b-form-group>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                value="1"
                :state="checkRecommendations.length >= 1"
              >
                AVOID INQUIRIES
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="2"
                :state="checkRecommendations.length >= 1"
              >
                CREDIT CARD BALANCES
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="3"
                :state="checkRecommendations.length >= 1"
              >
                DON'T CANCEL CARDS
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="4"
                :state="checkRecommendations.length >= 1"
              >
                COLLECTION CALLS
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                value="5"
                :state="checkRecommendations.length >= 1"
              >
                DON'T ACCEPT DEBT
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="6"
                :state="checkRecommendations.length >= 1"
              >
                COLLECTION LETTERS
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="7"
                :state="checkRecommendations.length >= 1"
              >
                BUREAUS LETTERS
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="8"
                :state="checkRecommendations.length >= 1"
              >
                SEND LETTERS
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="9"
                :state="checkRecommendations.length >= 1"
              >
                UB
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="10"
                :state="checkRecommendations.length >= 1"
              >
                ID
              </b-form-checkbox>
              <b-form-checkbox
                v-model="checkRecommendations"
                :disabled="disabled"
                class="mt-1"
                value="11"
                :state="checkRecommendations.length >= 1"
              >
                CONTACT
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        header="5) PERSONAL INFORMATION"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <div class="mt-2">
          <b-col>
            <b-row>
              <b-col cols="2">
                <span>DBA</span>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_first_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_middle_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_last_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="1" class="d-flex align-items-center">
                <validation-provider
                  v-slot="{ errors }"
                  name="dba"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkDba"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editdba"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="2">
                <span>ADDRESS</span>
              </b-col>
              <b-col>
                <vue-google-autocomplete
                  id="welcome_location"
                  v-model="welcome.client_street"
                  classname="input-form form-control fond-white"
                  placeholder="Please type your address"
                  :country="['us']"
                  style="height: 35px !important"
                  :disabled="adressedit"
                  @placechanged="getAddressData"
                />
              </b-col>
              <b-col cols="1" class="d-flex align-items-center">
                <validation-provider
                  v-slot="{ errors }"
                  name="address"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkAddress"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editadress"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="2" />
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_city"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-select
                  v-model="welcome.client_states_eeuu_slug"
                  :options="states"
                  text-field="state"
                  value-field="slug"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_zipcode"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="1" />
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>SSN</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input v-model="welcome.client_ssn" disabled />
                  </b-col>
                  <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="4">
                    <span>ITIN</span>
                  </b-col>
                  <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="6">
                    <b-form-input v-model="welcome.l_itin" disabled />
                  </b-col>
                  <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="4">
                    <span>CPN</span>
                  </b-col>
                  <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="6">
                    <b-form-input v-model="welcome.l_other" disabled />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="ssn"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkPersonSsn"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editssn"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>DOB</span>
                  </b-col>
                  <b-col cols="6">
                    <kendo-datepicker
                      v-model="welcome.client_dob"
                      v-mask="'##/##/####'"
                      class="kk-picker-wrap form-control"
                      :class="[
                        'w-100 bg-transparent k-picker-custom',
                        { 'text-white': isDarkSkin },
                      ]"
                      :format="'MM/dd/yyyy'"
                      :disabled="!!dobedit"
                      readonly
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="dob"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkPersonDob"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editdob"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>LEGAL STATUS</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-select
                      v-model="welcome.client_status_l"
                      :options="legalStatuses"
                      :disabled="statusedit"
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="legal_status"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkLegal"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editstatus"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>EMAIL</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      v-model="welcome.client_email"
                      :disabled="emailedit"
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkEmail"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editemail"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-center mt-2">
              <span class="font-weight-bolder">PAYMENT</span>
            </b-row>
            <b-row v-if="welcome.cf_type_payment === 0" class="mt-1">
              <b-col>
                <b-row>
                  <b-col>
                    <span>METHOD</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        Credit Card
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <span>TYPE</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        Automatic
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <span>DATE</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        {{ diames }}
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1">
                <validation-provider
                  v-slot="{ errors }"
                  name="payment"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkPersoAp"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editcard"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="welcome.cf_type_payment === 1" class="mt-1">
              <b-col>
                <b-row>
                  <b-col>
                    <span>METHOD</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        Credit card
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <span>TYPE</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        Manual
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1">
                <validation-provider
                  v-slot="{ errors }"
                  name="payment"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkPersoAp"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editcard"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="welcome.cf_type_payment === 2" class="mt-1">
              <b-col>
                <b-row>
                  <b-col>
                    <span>METHOD</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        Others
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1">
                <validation-provider
                  v-slot="{ errors }"
                  name="checkPersoAp"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkPersoAp"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editcard"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <b-row>
                  <b-col>
                    <span>MONTHLY PAYMENT</span>
                  </b-col>
                  <b-col>
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        $ {{ welcome.c_monthly_amount }}
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <span>FEE</span>
                  </b-col>
                  <b-col class="d-flex justify-content-end mr-1">
                    <h4 class="d-inline">
                      <b-badge
                        style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                        variant="light"
                      >
                        $ {{ welcome.client_fee_amount }}
                      </b-badge>
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" />
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-center mt-2">
              <span class="font-weight-bolder">CREDIT CARDS</span>
              <b-table :fields="fieldsCreditCard" :items="cards" class="mt-1">
                <template #cell(cvc)>
                  <span>XXX</span>
                </template>
              </b-table>
            </b-row>
          </b-col>
        </div>
      </b-card>
      <b-card
        header="6) FILE COMPLETED"
        header-bg-variant="primary"
        header-class="font-weight-bolder text-white py-1"
      >
        <div class="mt-2 d-flex justify-content-center">
          <b-form-radio
            v-model="checkFileNo"
            :value="true"
            :state="checkFileNo || checkFileYes"
            :disabled="disabled"
            @change="fileComplete(2)"
          >
            NO
          </b-form-radio>
          <b-form-radio
            v-model="checkFileYes"
            class="ml-1"
            :value="true"
            :state="checkFileNo || checkFileYes"
            :disabled="disabled"
            @change="fileComplete(1)"
          >
            YES
          </b-form-radio>
        </div>
      </b-card>
      <b-card
        header="PENDING"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <b-form-textarea
          v-model="welcome.w_pending"
          class="mt-2"
          :disabled="disabled"
        />
      </b-card>
      <b-card
        :header-bg-variant="disabled || checkDoubts ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled || checkDoubts ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
        no-body
      >
        <template #header>
          <span>7) DOUBTS OR QUESTION</span>
          <validation-provider v-slot="{ errors }" name="doubts" rules="truthy">
            <b-form-checkbox
              v-model="checkDoubts"
              :disabled="disabled"
              :state="errors[0] && validator ? false : null"
              @change="editDoubts"
            />
          </validation-provider>
        </template>
        <b-card-body class="mt-2">
          <b-form-textarea v-model="welcome.w_doubts" :disabled="doubtsedit" />
        </b-card-body>
      </b-card>
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="welcome.statuswelcome !== 1"
        variant="success"
        @click="saveUpdateWelcome"
      >
        DONE
      </b-button>
      <b-form-group v-else label="DONE BY:">
        <span class="k-font-weight-bold"
          >{{ welcome.w_user_name }} |
          {{ welcome.w_created_at | myGlobalDay() }}</span
        >
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapGetters } from "vuex";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";

export default {
  components: {
    quillEditor,
    VueGoogleAutocomplete,
    vSelect,
  },
  props: {
    client: {},
  },
  data() {
    return {
      onControl: false,
      validator: false,
      notes: [],
      disabled: false,
      diames: "",
      idAccount: "",
      information: "",
      checkPresentation: "",
      checkRecommendations: [],
      legalStatuses: [
        { text: "U.S Citizen", value: 1 },
        { text: "Residente Alien", value: 2 },
        { text: "Non-Resident Alien", value: 3 },
        { text: "Student Green Card", value: 4 },
        { text: "Work Green Card", value: 5 },
      ],
      checkFileYes: "",
      goalOptions: [],
      states: [],
      goalsedit: false,
      typeGoal: [],
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      isBusy: false,
      fields: [
        {
          key: "credit",
          label: "Creditors",
        },
        {
          key: "account",
          label: "# Account",
        },
        {
          key: "balance",
          label: "Total Balance",
        },
      ],
      fieldsCreditCard: [
        {
          key: "name_card",
          label: "Card Name",
        },
        {
          key: "n_card",
          label: "Card Number",
        },
        {
          key: "card_expi_month",
          label: "MM",
        },
        {
          key: "card_expi_year",
          label: "YY",
        },
        {
          key: "cvc",
          label: "CVC",
        },
      ],
      checkFileNo: "",
      checkProInfor: "",
      checkGoals: "",
      checkCreditors: "",
      checkInformation: "",
      welcome: {
        statuswelcome: null,
        id_note: null,
        information: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        cards: "",
        email: "",
        dob: "",
        ssn: "",
        itin: "",
        cpn: "",
        client_status_l: "",
        pending: "",
        client_fee_amount: "",
      },
      informationedit: "",
      checkDba: "",
      dbaedit: "",
      checkAddress: "",
      adressedit: false,
      checkPersonSsn: "",
      ssnedit: "",
      checkPersonDob: "",
      dobedit: "",
      checkLegal: "",
      statusedit: "",
      checkEmail: "",
      emailedit: "",
      checkPersoAp: "",
      lastName: "",
      checkDoubts: "",
      doubtsedit: "",
      items: [],
      cards: [],
      editorOption: {
        modules: { toolbar: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.onControl = true;
    this.isBusy = true;
    this.idAccount = this.$route.params.idClient;
    await this.getNotesWelcomeDs();
    this.setDataWelcome();
    await this.getStates();
    await this.getListTypeGoal();
    this.welcome.client_fee_amount = Number(this.welcome.client_fee_amount);
    this.cards = JSON.parse(this.welcome.client_cardnumber);

    this.disabled = this.welcome.w_status === 1;
    this.isBusy = false;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getStates() {
      try {
        const data = await ClientsOptionsServices.getStatesUSApi();
        if (data.status === 200) {
          this.states = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getNotesWelcomeDs() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.getNotesWelcomeDs({
          id: this.client.sale_id,
        });
        if (data.status === 200) {
          this.notes = data.data;
          this.welcome = this.notes[0];
          if (this.information === "") {
            this.information =
              "El motivo de la llamada es para darte la bienvenida formal al departamento de Boost Credit, asi mismo informarle que estare atento a cualquier duda o consulta referente a la formacion del credito. Queremos agradecer la confianza que nos ha brindado al enrrolarse en nuestra compañia.";
          }
          console.log(this.notes[6].answer)
          this.typeGoal = JSON.parse(this.notes[6].answer);
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async getListTypeGoal() {
      try {
        const response = await NotesServices.getListTypeGoal();
        this.goalOptions = response;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    editInformation() {
      this.informationedit = this.checkInformation === true;
    },
    editGoals() {
      this.goalsedit = this.checkGoals === true;
    },
    editdba() {
      this.dbaedit = this.checkDba === true;
    },
    editadress() {
      this.adressedit = this.checkAddress === true;
    },
    editssn() {
      this.ssnedit = this.checkPersonSsn === true;
    },
    editdob() {
      this.dobedit = this.checkPersonDob === true;
    },
    editstatus() {
      this.statusedit = this.checkLegal === true;
    },
    editemail() {
      this.emailedit = this.checkEmail === true;
    },
    editcard() {
      this.cardedit = this.checkPersoAp === true;
    },
    editDoubts() {
      this.doubtsedit = this.checkDoubts === true;
    },
    fileComplete(type) {
      this.checkFileNo = type === 2;
      this.checkFileYes = type === 1;
    },
    setDataWelcome() {
      this.welcome.statuswelcome = this.welcome.w_status;
      this.welcome.id_note = this.welcome.sale_id;
      if (this.checkPresentation === "") {
        this.checkPresentation = this.welcome.w_presentation === 1;
      }
      if (this.welcome.w_recomendations != null) {
        this.checkRecommendations = JSON.parse(this.welcome.w_recomendations);
      }
      this.fileComplete(this.welcome.w_file);
      this.checkProInfor = this.welcome.w_goals === 1;
      this.checkGoals = this.welcome.w_goals === 1;
      this.editGoals();
      this.checkCreditors = this.welcome.w_incovenience === 1;
      this.checkInformation = this.welcome.w_information === 1;
      this.editInformation();
      this.checkDba = this.welcome.w_dba === 1;
      this.editdba();
      this.checkAddress = this.welcome.w_adress === 1;
      this.editadress();
      this.checkPersonSsn = this.welcome.w_ssn === 1;
      this.editssn();
      this.checkPersonDob = this.welcome.w_dob === 1;
      this.editdob();
      this.checkLegal = this.welcome.w_legal_status === 1;
      this.editstatus();
      this.checkEmail = this.welcome.w_email === 1;
      this.editemail();
      this.checkPersoAp = this.welcome.w_card === 1;
      this.editcard();
      this.checkDoubts = this.welcome.w_c_doubts === 1;
      this.editDoubts();
      this.diames = `${
        this.welcome.cf_month_payment <= 9
          ? `0${this.welcome.cf_month_payment}`
          : this.welcome.cf_month_paymentt
      } / ${this.welcome.cf_day_payment}`;
    },

    getAddressData(addressPrincipal) {
      const mainAddress = addressPrincipal.street_number
        ? `${addressPrincipal.street_number} ${addressPrincipal.route}`
        : addressPrincipal.route;
      this.welcome.client_street = mainAddress || " ";
      this.welcome.client_city = addressPrincipal.locality
        ? addressPrincipal.locality
        : " ";
      this.welcome.client_states_eeuu_slug =
        addressPrincipal.administrative_area_level_1
          ? addressPrincipal.administrative_area_level_1
          : "";
      this.welcome.client_zipcode = addressPrincipal.postal_code
        ? addressPrincipal.postal_code
        : "";
    },
    async saveUpdateWelcome() {
      try {
        this.validator = true;
        const result1 = await this.$refs.form.validate();
        if (
          result1 &&
          this.checkRecommendations.length >= 1 &&
          (this.checkFileNo || this.checkFileYes)
        ) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.addPreloader();
            const dt = [];
            let values = [];
            dt.push({ number: 1049, value: this.typeGoal });
            dt.push({ number: 25, value: JSON.stringify(values) });
            const data = await ClientsOptionsServices.saveUpdateWelcomeDs({
              id: this.idAccount,
              presentation: this.checkPresentation,
              idnote: this.welcome.sale_id,
              note: dt,
              recommendations: this.checkRecommendations,
              pendingg: this.welcome.w_pending,
              c_file: this.checkFileYes === true ? 1 : 2,
              c_presentation: this.checkPresentation === true ? 1 : 2,
              c_goals: this.checkGoals === true ? 1 : 2,
              c_inconvenience: this.checkCreditors === true ? 1 : 2,
              c_information: this.checkInformation === true ? 1 : 2,
              c_dba: this.checkDba === true ? 1 : 2,
              c_adress: this.checkAddress === true ? 1 : 2,
              c_person_ssn: this.checkPersonSsn === true ? 1 : 2,
              c_person_dob: this.checkPersonDob === true ? 1 : 2,
              c_legal: this.checkLegal === true ? 1 : 2,
              c_email: this.checkEmail === true ? 1 : 2,
              c_perso_ap: this.checkPersoAp === true ? 1 : 2,
              legalstatus: this.welcome.client_status_l,
              lastname: this.welcome.client_last_name,
              middlename: this.welcome.client_middle_name,
              fisrtname: this.welcome.client_first_name,
              c_dob: this.welcome.client_dob,
              c_emaill: this.welcome.client_email,
              c_doubts: this.welcome.w_doubts,
              cdoubts: this.checkDoubts === true ? 1 : 2,
              userid: this.currentUser.user_id,
              c_street: this.welcome.client_street,
              c_city: this.welcome.client_city,
              c_states_eeuu_slug: this.welcome.client_states_eeuu_slug,
              c_zipcode: this.welcome.client_zipcode,
            });
            if (data.status === 200) {
              await this.sendMessage();
              this.showSuccessSwal();
              this.$emit("reload");
              this.close();
              this.removePreloader();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async sendMessage() {
      try {
        const data = await ClientsOptionsServices.sendMessage({
          account: this.$route.params.idClient,
          modul: this.currentUser.modul_id,
          number: null,
          sms: this.welcome.return_languaje,
          user: this.currentUser.user_id,
        });
        if (data.status === 200) {
          this.showSuccessSwal();
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>
<style scoped>
.disable-class {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
}
</style>
<style>
.kk-picker-wrap .k-picker-wrap {
  border-color: transparent !important;
}
.quill-br .ql-container {
  height: 200px !important;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
