<template>
  <b-modal
    v-model="onControl"
    title="WORK PLAN"
    title-tag="h3"
    modal-class="custom-modal-amg"
    header-class="p-0"
    header-bg-variant="transparent"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    scrollable
    no-close-on-backdrop
    :hide-footer="typeTap === 1 || typeTap === 0 ? false : true"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>WORK PLAN</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col>
          <b-card
            style="padding-bottom: 1.42rem !important"
            footer-class="p-1 d-flex justify-content-center"
            header="CLIENT"
            header-class="p-1 justify-content-center text-white"
            :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
            header-tag="header"
            :footer="clientName"
            footer-tag="footer"
            no-body
          />
        </b-col>
        <b-col>
          <b-card
            style="padding-bottom: 1.42rem !important"
            footer-class="p-1 d-flex justify-content-center"
            header="ACCOUNT"
            header-class="p-1 justify-content-center text-white"
            :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
            header-tag="header"
            :footer="account"
            footer-tag="footer"
            no-body
          />
        </b-col>
        <b-col v-if="hasBeenUpdated">
          <b-card
            footer-class="p-1 d-flex justify-content-center"
            header="UPDATED BY"
            header-class="p-1 justify-content-center text-white"
            :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
            header-tag="header"
            footer-tag="footer"
            no-body
          >
            <template #footer>
              <span class="text-center"
                >{{ workPlan[0].user_name }} <br />
                {{ workPlan[0].updated_at | myGlobalDay }}</span
              >
            </template>
          </b-card>
        </b-col>
      </b-row>
      <div v-if="isBusy" class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-1" />
        <strong>Loading ...</strong>
      </div>
      <transition name="fade">
        <b-row v-if="!isBusy">
          <b-col cols="12">
            <validation-observer ref="form">
              <div v-for="(work, index) in workPlan" :key="index">
                <validation-provider
                  v-slot="{ errors }"
                  :name="work.question"
                  rules="required"
                >
                  <b-form-group :label="work.question">
                    <b-form-textarea
                      v-if="statewp === 0"
                      :id="'answer' + work.id_question"
                      v-model="work.answer"
                      :disabled="valorDisabled"
                      :name="'answer' + work.id_question"
                    />

                    <b-form-textarea
                      v-if="statewp === 1 && stateedit === false"
                      :class="
                        isDarkSkin
                          ? 'textarea-style-work-dark'
                          : 'textarea-style-work'
                      "
                      :disabled="valorDisabled"
                      :name="'answer' + work.id_question"
                      style="resize: vertical"
                      v-model="work.answer"
                    />

                    <b-form-textarea
                      v-if="statewp === 1 && stateedit === true"
                      :id="'answer' + work.id_question"
                      v-model="work.answer"
                      :disabled="valorDisabled"
                      :name="'answer' + work.id_question"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      {{ work.question }} {{ errors[0] }}</span
                    >
                  </b-form-group>
                </validation-provider>
              </div>
            </validation-observer>
          </b-col>
        </b-row>
      </transition>
    </b-container>
    <template #modal-footer>
      <b-button
        v-if="statewp == 1 && currentUser.role_id == 1 && moodEditing"
        @click="editNotes"
      >
        EDIT NOTE
      </b-button>
      <b-button v-if="statewp == 0" variant="primary" @click="saveNote(1)">
        SAVE AND FINISH
      </b-button>
      <b-button
        v-if="stateedit == true && statewp == 1 && currentUser.role_id === 1"
        variant="primary"
        @click="saveNote(2)"
      >
        SAVE AND FINISH
      </b-button>
      <b-button
        v-if="statewp == 1 && currentUser.role_id === 1"
        variant="outline-danger"
        @click="deleteNote(2)"
      >
        DELETE NOTE
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ButtonSave,
  },
  props: {
    idNcr: null,
    clientName: null,
    account: null,
    idWorkPlan: null,
    client_id: null,
    hasRounds: null,
    typeTap: {
      type: Number,
      default: 0,
    },
    select: {
      type: Number,
      default: 0,
    },
    workplans: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      workPlan: [],
      isBusy: false,
      isEditingWork: false,
      statewp: 0,
      valorDisabled: 0,
      stateedit: 0,
      moodEditing: true,
      hasBeenUpdated: false,
    };
  },
  async created() {
    this.onControl = true;
    await this.getWorkPlan();

    this.hasBeenUpdated = this.workPlan[0].updated_at != null;
  },
  methods: {
    async getWorkPlan() {
      try {
        if (this.idWorkPlan != null) {
          this.isBusy = true;
          const data = await ClientsOptionsServices.getAllWorkPlan({
            id: this.client_id ? this.client_id : this.$route.params.idClient,
            id_ncr: this.idWorkPlan,
          });
          if (data.status === 200) {
            this.workPlan = data.data;
            this.isEditingWork = true;
            this.statewp = 1;
            this.stateedit = false;
            this.valorDisabled = true;
            this.isBusy = false;
          }
        } else {
          this.isBusy = true;
          const data = await ClientsOptionsServices.getAllWorkPlanWhenNull({
            id: this.client_id ? this.client_id : this.$route.params.idClient,
          });
          if (data.status === 200) {
            this.workPlan = data.data;
            this.isEditingWork = true;
            this.statewp = 0;
            this.stateedit = true;
            this.valorDisabled = false;
            this.isBusy = false;
          }
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    close() {
      this.$emit("close");
    },
    editNotes() {
      // this.workPlan.forEach(work => {
      //   work.answer = work.answer.replaceAll("\n", '<br />')
      // })
      this.valorDisabled = false;
      this.stateedit = true;
      this.moodEditing = false;
    },
    async saveNote(type) {
      try {
        const result1 = await this.$refs.form.validate();
        if (result1) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.addPreloader();
            const notes = [];
            this.workPlan.map((data) => {
              notes.push({
                id: data.id_answer,
                id_question: data.id_question.toString(),
                answer: data.answer.replace(/\r?\n/g, "<br />"),
              });
            });
            const data = await ClientsOptionsServices.saveWorkPlan({
              notes,
              idaccount: this.client_id
                ? this.client_id
                : this.$route.params.idClient,
              type,
              session: this.currentUser.user_id,
              id_workplan: type === 2 ? this.workPlan[0].id_work_plan : null,
              ncr_id: this.idNcr,
              hasRounds: this.hasRounds,
            });
            if (data.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.$emit("reload");
              this.close();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async deleteNote(type) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const data = await ClientsOptionsServices.deleteNoteAccount({
            id_workplan: type === 2 ? this.workPlan[0].id_work_plan : null,
            has_rounds: this.hasRounds,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$emit("reload");
            this.close();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style>
.textarea-style-work {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
}
.textarea-style-work[disabled] {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
  background-color: #efefef;
  opacity: 1;
}
.textarea-style-work-dark {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
}
.textarea-style-work-dark[disabled] {
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
  background-color: #17171a;
  opacity: 0.5;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
