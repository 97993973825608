import { render, staticRenderFns } from "./TaskNotes.vue?vue&type=template&id=aaa9e270&scoped=true"
import script from "./TaskNotes.vue?vue&type=script&lang=js"
export * from "./TaskNotes.vue?vue&type=script&lang=js"
import style0 from "./TaskNotes.vue?vue&type=style&index=0&id=aaa9e270&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa9e270",
  null
  
)

export default component.exports