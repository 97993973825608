<template>
  <div>
    <!--modal welcome -->
    <b-modal
      v-model="onControl"
      title="WELCOME"
      title-tag="h3"
      modal-class="custom-modal-amg"
      header-class="p-0"
      header-bg-variant="transparent"
      title-class="h3 text-white font-weight-bolder"
      size="lg"
      scrollable
      no-close-on-backdrop
      @hidden="close"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>WELCOME</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="close"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <validation-observer ref="form">
        <b-card
          no-body
          :header-bg-variant="disabled || checkPresentation ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled || checkPresentation ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <template #header>
            <span>1) PRESENTATION</span>
            <validation-provider
              v-slot="{ errors }"
              name="presentation"
              rules="truthy"
            >
              <b-form-checkbox
                v-model="checkPresentation"
                :disabled="disabled"
                :state="errors[0] && validator ? false : null"
              />
            </validation-provider>
          </template>
        </b-card>
        <b-card
          no-body
          :header-bg-variant="disabled || checkProInfor ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled || checkProInfor ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <template #header>
            <span>2) PROGRAM INFORMATION</span>
            <validation-provider
              v-slot="{ errors }"
              name="program"
              rules="truthy"
            >
              <b-form-checkbox
                v-model="checkProInfor"
                :disabled="disabled"
                :state="errors[0] && validator ? false : null"
              />
            </validation-provider>
          </template>
        </b-card>
        <b-card
          no-body
          :header-bg-variant="disabled || checkInformation ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled || checkInformation ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <template #header>
            <span> 3) INFORMATION</span>
            <validation-provider
              v-slot="{ errors }"
              name="information"
              rules="truthy"
            >
              <b-form-checkbox
                v-model="checkInformation"
                :disabled="disabled"
                :state="errors[0] && validator ? false : null"
                @change="editInformation"
              />
            </validation-provider>
          </template>
          <b-card-body class="mt-2">
            <!--          <b-form-textarea-->
            <!--            v-model="welcome.answer"-->
            <!--            style="height: 100px;"-->
            <!--            :disabled="informationedit"-->
            <!--          />-->
            <quill-editor
              v-model="welcome.answer"
              style="width: 100%"
              class="quill-br"
              :disabled="disabled"
              :options="editorOption"
            />
          </b-card-body>
        </b-card>
        <b-card
          header="4) RECOMMENDATIONS"
          :header-bg-variant="disabled ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <b-card-body>
            <div class="d-flex justify-content-between">
              <b-form-group>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  value="1"
                  :state="checkRecommendations.length >= 1"
                >
                  NO APPLICATIONS
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  class="mt-1"
                  value="2"
                  :state="checkRecommendations.length >= 1"
                >
                  COLLECTION LETTERS
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  class="mt-1"
                  value="3"
                  :state="checkRecommendations.length >= 1"
                >
                  UB
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  class="mt-1"
                  value="4"
                  :state="checkRecommendations.length >= 1"
                >
                  ID
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  value="5"
                  :state="checkRecommendations.length >= 1"
                >
                  CALLS
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  class="mt-1"
                  value="6"
                  :state="checkRecommendations.length >= 1"
                >
                  COURT LETTERS
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkRecommendations"
                  :disabled="disabled"
                  class="mt-1"
                  value="7"
                  :state="checkRecommendations.length >= 1"
                >
                  DEPARTMENT INFORMATION
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-card-body>
        </b-card>
        <b-card
          header="5) PERSONAL INFORMATION"
          :header-bg-variant="disabled ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <div class="mt-2">
            <b-col>
              <b-row>
                <b-col cols="2">
                  <span>DBA</span>
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    v-model="welcome.client_first_name"
                    :disabled="dbaedit"
                  />
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    v-model="welcome.client_middle_name"
                    :disabled="dbaedit"
                  />
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    v-model="welcome.client_last_name"
                    :disabled="dbaedit"
                  />
                </b-col>
                <b-col cols="1" class="d-flex align-items-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="dba"
                    rules="truthy"
                  >
                    <b-form-checkbox
                      v-model="checkDba"
                      :disabled="disabled"
                      :state="errors[0] && validator ? false : null"
                      @change="editdba"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="2">
                  <span>ADDRESS</span>
                </b-col>
                <b-col>
                  <vue-google-autocomplete
                    id="welcome_location"
                    v-model="welcome.client_street"
                    classname="input-form form-control fond-white"
                    placeholder="Please type your address"
                    :country="['us']"
                    style="height: 35px !important"
                    :disabled="adressedit"
                    @placechanged="getAddressData"
                  />
                </b-col>
                <b-col cols="1" class="d-flex align-items-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="address"
                    rules="truthy"
                  >
                    <b-form-checkbox
                      v-model="checkAddress"
                      :disabled="disabled"
                      :state="errors[0] && validator ? false : null"
                      @change="editadress"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="2" />
                <b-col cols="3">
                  <b-form-input
                    v-model="welcome.client_city"
                    :disabled="adressedit"
                  />
                </b-col>
                <b-col cols="3">
                  <b-form-select
                    v-model="welcome.client_states_eeuu_slug"
                    :options="states"
                    text-field="state"
                    value-field="slug"
                    :disabled="adressedit"
                  />
                </b-col>
                <b-col cols="3">
                  <b-form-input
                    v-model="welcome.client_zipcode"
                    :disabled="adressedit"
                  />
                </b-col>
                <b-col cols="1" />
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <span>SSN</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-input v-model="welcome.client_ssn" disabled />
                    </b-col>
                    <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="4">
                      <span>ITIN</span>
                    </b-col>
                    <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="6">
                      <b-form-input v-model="welcome.l_itin" disabled />
                    </b-col>
                    <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="4">
                      <span>CPN</span>
                    </b-col>
                    <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="6">
                      <b-form-input v-model="welcome.l_other" disabled />
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center">
                      <validation-provider
                        v-slot="{ errors }"
                        name="ssn"
                        rules="truthy"
                      >
                        <b-form-checkbox
                          v-model="checkPersonSsn"
                          :disabled="disabled"
                          :state="errors[0] && validator ? false : null"
                          @change="editssn"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <span>DOB</span>
                    </b-col>
                    <b-col cols="6">
                      <kendo-datepicker
                        v-model="welcome.client_dob"
                        v-mask="'##/##/####'"
                        class="kk-picker-wrap form-control"
                        :class="[
                          'w-100 bg-transparent k-picker-custom',
                          { 'text-white': isDarkSkin },
                        ]"
                        :format="'MM/dd/yyyy'"
                        :disabled="!!dobedit"
                        readonly
                      />
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center">
                      <validation-provider
                        v-slot="{ errors }"
                        name="dob"
                        rules="truthy"
                      >
                        <b-form-checkbox
                          v-model="checkPersonDob"
                          :disabled="disabled"
                          :state="errors[0] && validator ? false : null"
                          @change="editdob"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <span>LEGAL STATUS</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-select
                        v-model="welcome.client_status_l"
                        :options="legalStatuses"
                        :disabled="statusedit"
                      />
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center">
                      <validation-provider
                        v-slot="{ errors }"
                        name="client_status_l"
                        rules="truthy"
                      >
                        <b-form-checkbox
                          v-model="checkLegal"
                          :disabled="disabled"
                          :state="errors[0] && validator ? false : null"
                          @change="editstatus"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <span>EMAIL</span>
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        v-model="welcome.client_email"
                        :disabled="emailedit"
                      />
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center">
                      <validation-provider
                        v-slot="{ errors }"
                        name="email"
                        rules="truthy"
                      >
                        <b-form-checkbox
                          v-model="checkEmail"
                          :disabled="disabled"
                          :state="errors[0] && validator ? false : null"
                          @change="editemail"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <hr />
              <b-row class="d-flex justify-content-center mt-2">
                <span class="font-weight-bolder">PAYMENT</span>
              </b-row>
              <b-row v-if="welcome.cf_type_payment === 0" class="mt-1">
                <b-col>
                  <b-row>
                    <b-col>
                      <span>METHOD</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          Credit Card
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <span>TYPE</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          Automatic
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <span>DATE</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          {{ diames }}
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="payment"
                    rules="truthy"
                  >
                    <b-form-checkbox
                      v-model="checkPersoAp"
                      :disabled="disabled"
                      :state="errors[0] && validator ? false : null"
                      @change="editcard"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row v-if="welcome.cf_type_payment === 1" class="mt-1">
                <b-col>
                  <b-row>
                    <b-col>
                      <span>METHOD</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          Credit card
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <span>TYPE</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          Manual
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="payment"
                    rules="truthy"
                  >
                    <b-form-checkbox
                      v-model="checkPersoAp"
                      :disabled="disabled"
                      :state="errors[0] && validator ? false : null"
                      @change="editcard"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row v-if="welcome.cf_type_payment === 2" class="mt-1">
                <b-col>
                  <b-row>
                    <b-col>
                      <span>METHOD</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          Others
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="presentation"
                    rules="truthy"
                  >
                    <b-form-checkbox
                      v-model="checkPersoAp"
                      :disabled="disabled"
                      :state="errors[0] && validator ? false : null"
                      @change="editcard"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-row>
                    <b-col>
                      <span>MONTHLY PAYMENT</span>
                    </b-col>
                    <b-col>
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          $ {{ welcome.c_monthly_amount }}
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="d-flex justify-content-end">
                      <span>RETAINER FEE</span>
                    </b-col>
                    <b-col class="d-flex justify-content-end mr-1">
                      <h4 class="d-inline">
                        <b-badge
                          style="
                            color: #6e6b7b;
                            padding: 0.7rem;
                            font-size: 14px;
                          "
                          variant="light"
                        >
                          $ {{ welcome.client_fee_amount }}
                        </b-badge>
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1" />
              </b-row>
              <hr />
              <b-row class="d-flex justify-content-center mt-2">
                <span class="font-weight-bolder">CREDIT CARDS</span>
                <b-table :fields="fieldsCreditCard" :items="cards" class="mt-1">
                  <template #cell(cvc)>
                    <span>XXX</span>
                  </template>
                </b-table>
              </b-row>
            </b-col>
          </div>
        </b-card>
        <b-card
          :header-bg-variant="disabled ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <template #header>
            <span>6) ACCOUNT</span>
            <validation-provider
              v-slot="{ errors }"
              name="account"
              rules="truthy"
            >
              <b-form-checkbox
                v-model="checkCreditors"
                :disabled="disabled"
                :state="errors[0] && validator ? false : null"
              />
            </validation-provider>
          </template>
          <b-table
            class="mt-2"
            responsive
            :fields="fields"
            :items="items"
            show-empty
            :busy="isBusy"
          >
            <template #cell(balance)="data">
              <span>$ {{ data.item.balance | currency }}</span>
            </template>
            <template #custom-foot>
              <td />
              <td
                colspan="1"
                :class="isDarkSkin ? 'bg-dark text-white' : 'bg-light'"
                class="font-weight-bolder align-middle vertical-align"
              >
                Total:
              </td>
              <td
                colspan="1"
                :class="isDarkSkin ? 'bg-dark text-white' : 'bg-light'"
                class="align-middle vertical-align font-weight-bolder"
              >
                $ {{ welcome.total_balance | currency }}
              </td>
            </template>
          </b-table>
        </b-card>
        <b-card
          :header-bg-variant="disabled || checkDoubts ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled || checkDoubts ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
          no-body
        >
          <template #header>
            <span>7) DOUBTS OR QUESTION</span>
            <validation-provider
              v-slot="{ errors }"
              name="doubts"
              rules="truthy"
            >
              <b-form-checkbox
                v-model="checkDoubts"
                :disabled="disabled"
                :state="errors[0] && validator ? false : null"
                @change="editDoubts"
              />
            </validation-provider>
          </template>
          <b-card-body class="mt-2">
            <b-form-textarea
              v-model="welcome.w_doubts"
              :disabled="doubtsedit"
            />
          </b-card-body>
        </b-card>
        <b-card
          header="8) FILE COMPLETED"
          header-bg-variant="primary"
          header-class="font-weight-bolder text-white py-1"
        >
          <div class="mt-2 d-flex justify-content-center">
            <b-form-radio
              v-model="checkFileNo"
              :value="true"
              :state="checkFileNo || checkFileYes"
              :disabled="disabled"
              @change="fileComplete(2)"
            >
              NO
            </b-form-radio>
            <b-form-radio
              v-model="checkFileYes"
              class="ml-1"
              :value="true"
              :state="checkFileNo || checkFileYes"
              :disabled="disabled"
              @change="fileComplete(1)"
            >
              YES
            </b-form-radio>
          </div>
        </b-card>
        <!--contact schedule -->
        <b-card
          header=""
          :header-bg-variant="checkContactSchedule ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="checkContactSchedule ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <template #header>
            <span>9) Contact Schedule</span>
            <validation-provider
              v-slot="{ errors }"
              name="contactSchedule"
              rules="truthy"
            >
              <b-form-checkbox
                :disabled="disabled"
                v-model="checkContactSchedule"
                class="pointer-events-none"
                :state="errors[0] && validator ? false : null"
              />
            </validation-provider>
          </template>
          <b-row class="p-1">
            <b-col sm="4">
              <b-form-group label="Day " label-for="day">
                <v-select
                  id="day"
                  :options="days"
                  :reduce="(options) => options.value"
                  v-model="dayContactSchedule"
                  label="text"
                  :disabled="
                    alwaysAvailable || welcome.statuswelcome ? true : false
                  "
                ></v-select>
              </b-form-group>
              <b-form-group label="Hour From" label-for="hour_from">
                <kendo-timepicker
                  v-model="hourContactScheduleFrom"
                  :format="'HH:mm'"
                  v-mask="'##:##'"
                  minutes-step="30"
                  class="w-100 rounded bg-transparent k-picker-custom"
                  style="height: 2.73rem"
                  placeholder="00:00"
                  :disabled="
                    alwaysAvailable || welcome.statuswelcome ? true : false
                  "
                />
              </b-form-group>
              <b-form-group label="Hour To" label-for="hour_to">
                <kendo-timepicker
                  v-model="hourContactScheduleTo"
                  :format="'HH:mm'"
                  v-mask="'##:##'"
                  minutes-step="30"
                  class="w-100 rounded bg-transparent k-picker-custom"
                  style="height: 2.73rem"
                  placeholder="00:00"
                  :disabled="
                    alwaysAvailable || welcome.statuswelcome ? true : false
                  "
                />
              </b-form-group>
              <b-form-group label="Priority" label-for="priority">
                <v-select
                  v-model="priorityContactSchedule"
                  label="label"
                  :disabled="
                    alwaysAvailable || welcome.statuswelcome ? true : false
                  "
                  :options="[
                    { value: 1, label: 'High' },
                    { value: 2, label: 'Medium' },
                    { value: 3, label: 'Low' },
                  ]"
                  :reduce="(option) => option.value"
                />
              </b-form-group>
              <div class="d-flex justify-content-center align-items-center">
                <b-form-checkbox
                  v-model="alwaysAvailable"
                  :disabled="welcome.statuswelcome ? true : false"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                  id="alwaysAvailable"
                />
                <label for="alwaysAvailable" class="font-medium-1 mt-1"
                  >Always available</label
                >
              </div>
              <div
                class="d-flex justify-content-center align-items-center mb-2"
              >
                <b-button
                  :disabled="welcome.statuswelcome ? true : false"
                  variant="primary"
                  class="mt-1"
                  @click="addContactSchedule"
                  >Add</b-button
                >
              </div>
            </b-col>
            <b-col sm="8">
              <b-list-group>
                <template v-if="contactScheduleAlwaysAvailable">
                  <b-list-group-item
                    class="w-100 text-center mt-5 pt-3 pb-3 position-relative"
                    :class="welcome.statuswelcome ? 'disabled' : ''"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="70"
                      class="text-success"
                    ></feather-icon>
                    <label for="" class="w-100 mt-2 font-medium-1"
                      >Always Available</label
                    >
                    <feather-icon
                      icon="TrashIcon"
                      size="18"
                      class="text-danger mt-2 cursor-pointer"
                      @click="deleteDate(-1)"
                    ></feather-icon>
                  </b-list-group-item>
                </template>
                <template v-else-if="!haveContactSchedule">
                  <b-list-group-item
                    class="w-100 text-center disabled border-0 mt-5"
                  >
                    <feather-icon icon="ClockIcon" size="70"></feather-icon>
                    <label for="" class="w-100 mt-2 font-medium-1"
                      >Not contact schedule</label
                    >
                  </b-list-group-item>
                </template>
                <template v-else>
                  <div
                    v-for="(date, index) in arrayPartContactSchedule"
                    :key="index"
                  >
                    <b-list-group-item v-if="date.deleted_at == null">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div
                          class="d-flex justify-content-start align-items-center w-100"
                        >
                          <div class="mr-1">
                            <feather-icon
                              icon="CheckIcon"
                              class="text-success"
                              size="17"
                              v-if="date.state == 2"
                              v-b-tooltip.hover
                              title="Confirmed"
                            ></feather-icon>
                            <feather-icon
                              v-if="date.priority != 3"
                              icon="AlertTriangleIcon"
                              size="17"
                              v-b-tooltip.hover
                              :title="
                                date.priority == 1
                                  ? 'High Priority'
                                  : 'Medium Priority'
                              "
                              class="ml-1"
                              :class="
                                date.priority == 1
                                  ? 'text-danger'
                                  : 'text-warning'
                              "
                            ></feather-icon>
                          </div>
                          <small>
                            <feather-icon icon="CalendarIcon"></feather-icon>
                            {{ date.date }}
                          </small>
                        </div>
                        <div class="d-flex justify-content-end w-100">
                          <template>
                            <div
                              for=""
                              class="ml-2"
                              :class="!welcome.statuswelcome ? '' : 'mr-2'"
                            >
                              <feather-icon icon="ClockIcon"></feather-icon>
                              <small v-if="!date.time_to">
                                From {{ date.time_from | myTime }}
                              </small>
                              <small v-else>
                                {{ date.time_from | myTime }} -
                                {{ date.time_to | myTime }}
                              </small>
                            </div>
                            <feather-icon
                              v-if="!welcome.statuswelcome"
                              icon="TrashIcon"
                              class="ml-3 text-danger cursor-pointer"
                              @click="deleteDate(index)"
                            ></feather-icon>
                          </template>
                        </div>
                      </div>
                    </b-list-group-item>
                  </div>
                </template>
              </b-list-group>
              <div
                class="d-flex justify-content-center align-items-center mt-1"
              >
                <b-button
                  variant="info"
                  v-if="fullviewButton"
                  class="mr-3 font-weight-bold underline"
                  @click="showMoreContactSchedule = !showMoreContactSchedule"
                >
                  <feather-icon
                    icon="EyeIcon"
                    style="margin-right: 3px"
                  ></feather-icon>
                  Full View
                </b-button>
                <b-button
                  variant="success"
                  :disabled="confirmButton"
                  @click="confirmContactSchedule"
                >
                  Confirm
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <!--pending -->
        <b-card
          header="PENDING"
          :header-bg-variant="disabled ? 'primary' : ''"
          border-variant="primary"
          :header-text-variant="disabled ? '' : 'primary'"
          header-class="font-weight-bolder text-white py-1"
        >
          <b-form-textarea
            v-model="welcome.w_pending"
            class="mt-2"
            :disabled="disabled"
          />
        </b-card>
      </validation-observer>
      <template #modal-footer>
        <b-button
          v-if="!welcome.statuswelcome"
          variant="success"
          @click="saveUpdateWelcome"
        >
          DONE
        </b-button>
        <b-form-group v-else label="DONE BY:">
          <span class="k-font-weight-bold"
            >{{ welcome.w_user_name }} |
            {{ welcome.w_created_at | myGlobalDay() }}</span
          >
        </b-form-group>
      </template>
    </b-modal>

    <!--modal view contact schedule-->
    <b-modal
      v-model="showMoreContactSchedule"
      title="Full Contact schedule"
      title-tag="h3"
      modal-class="modal-primary"
      title-class="h3 text-white font-weight-bolder"
      scrollable
      size="lg"
    >
      <b-card no-body class="mb-0">
        <div
          v-for="(date, index) in contactScheduleList.filter(
            (item) => !item.deleted_at
          )"
          :key="index"
          class="list-group-item bg-transparent rounded-0"
          :class="isDarkSkin ? 'border-light' : ''"
        >
          <b-row v-if="date.deleted_at == null">
            <b-col cols="2">
              <feather-icon
                icon="CheckIcon"
                class="text-success"
                size="17"
                v-if="date.state == 2"
                v-b-tooltip.hover
                title="Confirmed"
              ></feather-icon>
              <feather-icon
                v-if="date.priority != 3"
                icon="AlertTriangleIcon"
                size="17"
                v-b-tooltip.hover
                :title="
                  date.priority == 1 ? 'High Priority' : 'Medium Priority'
                "
                class="ml-1"
                :class="date.priority == 1 ? 'text-danger' : 'text-warning'"
              ></feather-icon>
            </b-col>
            <b-col cols="4">
              <small for="" style="font-size: 13px !important">
                <feather-icon icon="CalendarIcon" class="mr-1"></feather-icon>
                {{ date.date }}
              </small>
            </b-col>
            <b-col cols="4">
              <feather-icon icon="ClockIcon" class="mr-1"></feather-icon>
              <small style="font-size: 13px !important" v-if="!date.time_to">
                From {{ date.time_from | myTime }}
              </small>
              <small style="font-size: 13px !important" v-else>
                {{ date.time_from | myTime }} -
                {{ date.time_to | myTime }}
              </small>
            </b-col>
            <b-col cols="1">
              <feather-icon
                v-if="!welcome.statuswelcome"
                icon="TrashIcon"
                class="ml-2 text-danger cursor-pointer"
                @click="deleteDate(index)"
              ></feather-icon>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <template #modal-footer>
        <b-button variant="primary" @click="showMoreContactSchedule = false">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    quillEditor,
    VueGoogleAutocomplete,
  },
  props: {
    client: {},
  },
  data() {
    return {
      onControl: false,
      validator: false,
      notes: [],
      disabled: false,
      diames: "",
      idAccount: "",
      checkPresentation: "",
      checkRecommendations: [],
      legalStatuses: [
        { text: "U.S Citizen", value: 1 },
        { text: "Residente Alien", value: 2 },
        { text: "Non-Resident Alien", value: 3 },
        { text: "Student Green Card", value: 4 },
        { text: "Work Green Card", value: 5 },
      ],
      checkFileYes: "",
      states: [],
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      isBusy: false,
      fields: [
        {
          key: "credit",
          label: "Creditors",
        },
        {
          key: "account",
          label: "# Account",
        },
        {
          key: "balance",
          label: "Total Balance",
        },
      ],
      fieldsCreditCard: [
        {
          key: "name_card",
          label: "Card Name",
        },
        {
          key: "n_card",
          label: "Card Number",
        },
        {
          key: "card_expi_month",
          label: "MM",
        },
        {
          key: "card_expi_year",
          label: "YY",
        },
        {
          key: "cvc",
          label: "CVC",
        },
      ],
      checkFileNo: "",
      checkProInfor: "",
      checkCreditors: "",
      checkInformation: "",
      checkContactSchedule: false,
      welcome: {
        statuswelcome: null,
        id_note: null,
        information: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        cards: "",
        email: "",
        dob: "",
        ssn: "",
        itin: "",
        cpn: "",
        client_status_l: "",
        pending: "",
        client_fee_amount: "",
      },
      informationedit: "",
      checkDba: "",
      dbaedit: "",
      checkAddress: "",
      adressedit: false,
      checkPersonSsn: "",
      ssnedit: "",
      checkPersonDob: "",
      dobedit: "",
      checkLegal: "",
      statusedit: "",
      checkEmail: "",
      emailedit: "",
      checkPersoAp: "",
      lastName: "",
      checkDoubts: "",
      doubtsedit: "",
      items: [],
      cards: [],
      editorOption: {
        modules: { toolbar: false },
      },
      days: [
        {
          value: "",
          text: "Select",
        },
        {
          value: "Monday",
          text: "Monday",
        },
        {
          value: "Tuesday",
          text: "Tuesday",
        },
        {
          value: "Wednesday",
          text: "Wednesday",
        },
        {
          value: "Thursday",
          text: "Thursday ",
        },
        {
          value: "Friday",
          text: "Friday",
        },
        {
          value: "Saturday",
          text: "Saturday",
        },
        {
          value: "Sunday",
          text: "Sunday",
        },
      ],
      dayContactSchedule: "",
      hourContactScheduleFrom: "",
      hourContactScheduleTo: "",
      priorityContactSchedule: "",
      contactScheduleList: [],
      arrayPartContactSchedule: [],
      showMoreContactSchedule: false,
      alwaysAvailable: false,
      contactScheduleAlwaysAvailable: false,
      // confirmButton: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    confirmButton() {
      //There are contact schedules that are not confirmed
      return this.contactScheduleList.filter(
        (item) => item.state == 1 && !item.deleted_at
      ).length > 0
        ? false
        : true;
    },
    fullviewButton() {
      if (
        !this.contactScheduleAlwaysAvailable &&
        this.contactScheduleList.filter((item) => !item.deleted_at).length > 10
      ) {
        return true;
      }
      return false;
    },
    haveContactSchedule() {
      if (
        this.contactScheduleList.filter((item) => !item.deleted_at).length > 0
      ) {
        this.contactScheduleList.filter(
          (item) => !item.deleted_at && item.state == 1
        ).length > 0
          ? (this.checkContactSchedule = false)
          : (this.checkContactSchedule = true);
        return true;
      }

      this.checkContactSchedule = false;
      return false;
    },
    currentDate() {
      return {
        day: moment().format("dddd").toUpperCase(),
        hour: moment().format("HH:mm:ss"),
      };
    },
  },
  async created() {
    this.onControl = true;
    this.isBusy = true;
    this.idAccount = this.$route.params.idClient;
    await this.getNotesWelcomeDs();
    this.setDataWelcome();
    await this.getStates();
    this.welcome.client_fee_amount = Number(this.welcome.client_fee_amount);
    this.items = JSON.parse(this.welcome.creditors);
    this.cards = JSON.parse(this.welcome.client_cardnumber);
    this.disabled = this.welcome.w_status === 1;
    this.isBusy = false;
  },
  methods: {
    addContactSchedule() {
      if (this.alwaysAvailable && this.contactScheduleAlwaysAvailable) {
        this.showInfoSwal("This option already used");
        return;
      } else if (this.alwaysAvailable) {
        this.contactScheduleAlwaysAvailable = true;
        // this.confirmButton = false;
        this.checkContactSchedule = true;
        this.contactScheduleList = [];
        this.arrayPartContactSchedule = [];
        return;
      }
      if (
        !this.alwaysAvailable &&
        !this.deleted_at &&
        (this.dayContactSchedule == "" ||
          this.hourContactScheduleFrom == "" ||
          this.priorityContactSchedule == "")
      ) {
        this.showInfoSwal("All fields are required");
        return;
      }
      if (
        !this.alwaysAvailable &&
        this.hourContactScheduleTo != "" &&
        this.hourContactScheduleFrom >= this.hourContactScheduleTo
      ) {
        this.showInfoSwal(
          "'Hour From' can not be greater than o equals than 'Hour To'"
        );
        return;
      }

      //duplication of dates
      let existDate = false;
      if (!this.alwaysAvailable) {
        this.contactScheduleList.forEach((item) => {
          if (
            item.date === this.dayContactSchedule &&
            item.time_from === this.hourContactScheduleFrom &&
            item.time_to === this.hourContactScheduleTo &&
            !item.deleted_at
          ) {
            // this.checkContactSchedule = false;
            !this.contactScheduleAlwaysAvailable
              ? this.showInfoSwal("Already date exists")
              : null;
            this.contactScheduleAlwaysAvailable = false;
            existDate = true;
            return false;
          }
        });
      }

      if (!existDate) {
        const item = {
          sales_id: this.welcome.sale_id,
          date: this.alwaysAvailable
            ? this.dayContactSchedule
            : this.dayContactSchedule,
          time_from: this.alwaysAvailable ? null : this.hourContactScheduleFrom,
          time_to:
            this.alwaysAvailable || this.hourContactScheduleTo == ""
              ? null
              : this.hourContactScheduleTo,
          priority: this.alwaysAvailable ? "3" : this.priorityContactSchedule,
          user_id: this.currentUser.user_id,
          state: this.alwaysAvailable ? 0 : 1,
          deleted_at: null,
        };

        this.dayContactSchedule = "";
        this.hourContactScheduleFrom = "";
        this.hourContactScheduleTo = "";
        this.priorityContactSchedule = "";
        // this.checkContactSchedule = false;
        this.contactScheduleAlwaysAvailable = false;
        this.contactScheduleList.unshift(item);
        // this.confirmButton = true;
        this.arrayPartContactSchedule = this.contactScheduleList.slice(0, 10);
      }
    },
    deleteDate(index) {
      if (this.contactScheduleAlwaysAvailable) {
        this.contactScheduleAlwaysAvailable = false;
        return;
      }
      this.contactScheduleList[index].deleted_at = moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    confirmContactSchedule() {
      if (!this.contactScheduleAlwaysAvailable) {
        this.contactScheduleList.forEach((item) => {
          item.state = 2;
        });
      }
    },
    close() {
      this.$emit("close");
    },
    async getStates() {
      try {
        const data = await ClientsOptionsServices.getStatesUSApi();
        if (data.status === 200) {
          this.states = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getNotesWelcomeDs() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.getNotesWelcomeDs({
          id: this.client.sale_id,
        });

        if (data.status === 200) {
          this.notes = data.data;
          this.contactScheduleAlwaysAvailable = this.notes.some(
            (item) => item.question_id == 1085 && item.answer == 1
          )
            ? true
            : false;

          this.checkContactSchedule = this.contactScheduleAlwaysAvailable
            ? true
            : this.contactScheduleList.length > 0
            ? true
            : false;

          if (!this.contactScheduleAlwaysAvailable) {
            //search schedule pending of confirmation
            this.contactScheduleList = this.notes[0].schedule_client
              ? JSON.parse(this.notes[0].schedule_client)
              : [];
            this.arrayPartContactSchedule = this.contactScheduleList.slice(
              0,
              10
            );
            this.contactScheduleList.forEach((element) => {
              if (element.state == 1) {
                this.checkContactSchedule = false;
                // this.confirmButton = true;
                return false;
              }
            });
            // this.fullviewButton =
            //   this.contactScheduleList.length > 10 ? true : false;
          }
          this.welcome = this.notes[0];
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    editInformation() {
      this.informationedit = this.checkInformation === true;
    },
    editdba() {
      this.dbaedit = this.checkDba === true;
    },
    editadress() {
      this.adressedit = this.checkAddress === true;
    },
    editssn() {
      this.ssnedit = this.checkPersonSsn === true;
    },
    editdob() {
      this.dobedit = this.checkPersonDob === true;
    },
    editstatus() {
      this.statusedit = this.checkLegal === true;
    },
    editemail() {
      this.emailedit = this.checkEmail === true;
    },
    editcard() {
      this.cardedit = this.checkPersoAp === true;
    },
    editDoubts() {
      this.doubtsedit = this.checkDoubts === true;
    },
    fileComplete(type) {
      this.checkFileNo = type === 2;
      this.checkFileYes = type === 1;
    },
    setDataWelcome() {
      this.welcome.statuswelcome = this.welcome.w_status;
      this.welcome.id_note = this.welcome.sale_id;
      if (this.checkPresentation === "") {
        this.checkPresentation = this.welcome.w_presentation === 1;
      }
      if (this.welcome.w_recomendations != null) {
        this.checkRecommendations = JSON.parse(this.welcome.w_recomendations);
      }
      this.fileComplete(this.welcome.w_file);
      this.checkProInfor = this.welcome.w_goals === 1;
      this.checkCreditors = this.welcome.w_incovenience === 1;
      this.checkInformation = this.welcome.w_information === 1;
      this.editInformation();
      this.checkDba = this.welcome.w_dba === 1;
      this.editdba();
      this.checkAddress = this.welcome.w_adress === 1;
      this.editadress();
      this.checkPersonSsn = this.welcome.w_ssn === 1;
      this.editssn();
      this.checkPersonDob = this.welcome.w_dob === 1;
      this.editdob();
      this.checkLegal = this.welcome.w_legal_status === 1;
      this.editstatus();
      this.checkEmail = this.welcome.w_email === 1;
      this.editemail();
      this.checkPersoAp = this.welcome.w_card === 1;
      this.editcard();
      this.checkDoubts = this.welcome.w_c_doubts === 1;
      this.editDoubts();
      this.diames = `${
        this.welcome.cf_month_payment <= 9
          ? `0${this.welcome.cf_month_payment}`
          : this.welcome.cf_month_paymentt
      } / ${this.welcome.cf_day_payment}`;
    },
    getAddressData(addressPrincipal) {
      const mainAddress = addressPrincipal.street_number
        ? `${addressPrincipal.street_number} ${addressPrincipal.route}`
        : addressPrincipal.route;
      this.welcome.client_street = mainAddress || " ";
      this.welcome.client_city = addressPrincipal.locality
        ? addressPrincipal.locality
        : " ";
      this.welcome.client_states_eeuu_slug =
        addressPrincipal.administrative_area_level_1
          ? addressPrincipal.administrative_area_level_1
          : "";
      this.welcome.client_zipcode = addressPrincipal.postal_code
        ? addressPrincipal.postal_code
        : "";
    },
    async saveUpdateWelcome() {
      try {
        this.validator = true;
        const result1 = await this.$refs.form.validate();
        if (
          result1 &&
          this.checkRecommendations.length >= 1 &&
          (this.checkFileNo || this.checkFileYes)
        ) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.addPreloader();
            const dt = [];
            dt.push({ number: 29, value: this.welcome.answer });
            const data = await ClientsOptionsServices.saveUpdateWelcomeDs({
              id: this.idAccount,
              presentation: this.checkPresentation,
              idnote: this.welcome.sale_id,
              note: dt,
              recommendations: this.checkRecommendations,
              pendingg: this.welcome.w_pending,
              c_file: this.checkFileYes === true ? 1 : 2,
              c_presentation: this.checkPresentation === true ? 1 : 2,
              c_goals: this.checkProInfor === true ? 1 : 2,
              c_inconvenience: this.checkCreditors === true ? 1 : 2,
              c_information: this.checkInformation === true ? 1 : 2,
              c_dba: this.checkDba === true ? 1 : 2,
              c_adress: this.checkAddress === true ? 1 : 2,
              c_person_ssn: this.checkPersonSsn === true ? 1 : 2,
              c_person_dob: this.checkPersonDob === true ? 1 : 2,
              c_legal: this.checkLegal === true ? 1 : 2,
              c_email: this.checkEmail === true ? 1 : 2,
              c_perso_ap: this.checkPersoAp === true ? 1 : 2,
              legalstatus: this.welcome.client_status_l,
              lastname: this.welcome.client_last_name,
              middlename: this.welcome.client_middle_name,
              fisrtname: this.welcome.client_first_name,
              c_dob: this.welcome.client_dob,
              c_emaill: this.welcome.client_email,
              c_doubts: this.welcome.w_doubts,
              cdoubts: this.checkDoubts === true ? 1 : 2,
              userid: this.currentUser.user_id,
              c_street: this.welcome.client_street,
              c_city: this.welcome.client_city,
              c_states_eeuu_slug: this.welcome.client_states_eeuu_slug,
              c_zipcode: this.welcome.client_zipcode,
              contact_schedule: this.contactScheduleList.filter(
                (item) => !item.deleted_at
              ),
              always_available: this.contactScheduleAlwaysAvailable,
            });
            if (data.status === 200) {
              await this.sendMessage();
              this.showSuccessSwal();
              this.$emit("reload");
              this.close();
              this.removePreloader();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async sendMessage() {
      try {
        const data = await ClientsOptionsServices.sendMessage({
          account: this.$route.params.idClient,
          modul: this.currentUser.modul_id,
          number: null,
          sms: this.welcome.return_languaje,
          user: this.currentUser.user_id,
        });
        if (data.status === 200) {
          this.showSuccessSwal();
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
  filters: {
    weekday(val) {
      return {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        0: "Sunday",
      }[val];
    },
  },
  watch: {
    hourContactScheduleFrom(val) {},
  },
};
</script>

<style>
.kk-picker-wrap .k-picker-wrap {
  border-color: transparent !important;
}
.quill-br .ql-container {
  height: 200px !important;
}
.now {
  color: #00cc00 !important;
  font-weight: bold !important;
  animation: grow_up 0.8s ease-in-out 3;
}
.past {
  color: #fc424a !important;
  font-weight: bold !important;
}
.future {
  color: #0090e7 !important;
  font-weight: bold !important;
}
.now small,
.past small,
.future small {
  font-weight: bold !important;
  color: currentColor;
}
@keyframes grow_up {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
