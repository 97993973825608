<template>
  <div>
    <div class="title">{{ title }}</div>
    <b-table
      id="my-table"
      :current-page="paginateCredit.currentPage"
      :per-page="paginateCredit.perPage"
      striped
      small
      :items="items"
      :fields="fields"
    >
      <template #cell(bureau_id)="{ item }">
        <b-img
          class="mr-1 ml-1"
          v-b-tooltip.hover.top="
            item.bureau_id == 1
              ? 'Transunion'
              : item.bureau_id == 2
              ? 'Experian'
              : 'Equifax'
          "
          :src="
            item.bureau_id == 1
              ? '/assets/images/icons/transunion.png'
              : item.bureau_id == 2
              ? '/assets/images/icons/experian.png'
              : '/assets/images/icons/equifax.png'
          "
        />
      </template>
      <template v-slot:cell(status)="data">
        <b-badge :variant="getColorStatus(data.value)">
          {{ statusName(data.value) }}
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Accounts",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paginateCredit: {
        currentPage: 1,
        perPage: 20,
      },
      rowsCredit: this.items.length,
      fields: [
        { key: "account", label: "Account", thStyle: { width: "30%" } },
        {
          key: "account_number",
          label: "A. Number",
          thStyle: { width: "20%" },
        },
        {
          key: "status",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
        {
          key: "bureau_id",
          label: "Bureau",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
        {
          key: "balance",
          label: "Balance",
          formatter: (value) => `$ ${value}`,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { width: "20%" },
        },
      ],
    };
  },
  methods: {
    statusName(status) {
      let obj = {
        1: "IN DISPUTE",
        2: "VERIFIED",
        3: "UPDATED",
        4: "DELETED",
        5: "POSITIVE",
        6: "NEGATIVE",
      };
      return obj[status] || "light-success";
    },
    getColorStatus(number) {
      let setColorStatus = {
        1: "light-warning",
        2: "light-danger",
        5: "light-primary",
        6: "light-danger",
      };

      return setColorStatus[number] || "light-success";
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-input .form-control {
  opacity: 1 !important;
}
.hr-divider {
  height: 1;
  border-right: 1px solid #555555;
}
.info-strategy {
  min-height: 300px;
  width: 46%;
  padding: 5px 10px;
}
.container-complete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: #ff9c3f;
}
.counter-round {
  background: #42c7fc;
  color: #17161a !important;
}
</style>
